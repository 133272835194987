@charset 'utf-8';

/*
-----------------------------------------
Loader remote control
-----------------------------------------
*/

$loader-enabled: false;

/*
-----------------------------------------
Imports
-----------------------------------------
*/

// Base
@import "00-base/01-functions/**/!(_*).scss";

// Loader
@import "@layout/loader/loader";