@use 'sass:math';

/*
-----------------------------------------
Convert px to rem
-----------------------------------------
*/

@function rem($pixels) {
  @if (not unitless($pixels)) {
    $pixels: math.div($pixels, ($pixels * 0 + 1));
  }
  @return #{math.div($pixels, 10)}rem;
}