/*
-----------------------------------------
Variables
-----------------------------------------
*/

$loader-baseClass: '.loader';

/*
-----------------------------------------
Loader
-----------------------------------------
*/

#{$loader-baseClass} {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #fff;
	z-index: 999;
	// Inner
	&__inner {
		animation: animate 1.5s linear infinite;
		clip: rect(0, 80px, 80px, 40px);
		height: 80px;
		width: 80px;
		position: absolute;
		left: calc(50% - 40px);
		top: calc(50% - 40px);
		z-index: 100;

		&::after {
			content: "";
			animation: animate-pseudo 1.5s ease-in-out infinite;
			clip: rect(0, 80px, 80px, 40px);
			border-radius: 50%;
			height: 80px;
			width: 80px;
			position: absolute;
		}
	}
}

// Keyframes
// --------------------------------------

// Animate
@keyframes animate {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(220deg);
	}
}

// Animate Pseudo
@keyframes animate-pseudo {
	0% {
		box-shadow: inset 0 0 0 1px #983D40;
		transform: rotate(-140deg);
	}
	50% {
		box-shadow: inset 0 0 0 1px #983D40;
	}
	to {
		box-shadow: inset 0 0 0 1px #983D40;
		transform: rotate(140deg);

	}
}
